import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import "./viewrecord.scss"
import { styled } from '@mui/material/styles';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Swal from 'sweetalert2';
import { createFeePayment, getStudents } from '../../../Api';
import { ArrowBack } from '@mui/icons-material';

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    width: '100%',
    color: '#000',
    '&.Mui-focused': {
        color: '#000',
    },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#8267ed',
    },
}));

function ViewRecordFeePayment() {
    const navigate = useNavigate();

    const [selectedStudent, setSelectedStudent] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [students, setStudents] = useState([]);
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const monthName = (num) => {
        return months[num];
    }

    const monthIndex = (name) => {
        return months.indexOf(name);
    }

    const handleStudentChange = (event) => {
        setSelectedStudent(event.target.value);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event["$d"].getFullYear());
        console.log(event);
    };

    const submit = async () => {
        if (!selectedMonth) {
            Swal.fire({
                text: 'Please Select Month!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let data = {
                student_id: selectedStudent,
                month: monthIndex(selectedMonth),
                year: selectedYear,
            };
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            data.account_code = userData.account_code;
            console.log(data);
            let apiData = await createFeePayment({ ...data });
            console.log(apiData);
            if (apiData.status === "S") {
                Swal.fire({
                    text: 'Record created successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-payments')
                })
            }
            if (apiData.status === "E") {
                Swal.fire({
                    text: apiData.message,
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        }
    }

    useEffect(() => {
        (async () => {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let acc_code = userData.account_code;
            let apiData = await getStudents(acc_code);
            apiData.status === "S" ? setStudents(apiData.result_info) : setStudents([]);
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        })();
    }, [navigate])

    const handleGoBack = () => {
        navigate(-1); // Equivalent to history.goBack()
    };
    return (
        <div className='editContainer'>
            <h5 className='page-heading'>Record Fee Payment</h5>
            <div className="row mb-lg-3">
                <div className="col-12 col-lg-6 mb-3">
                    <FormControl fullWidth>
                        <CustomInputLabel className='w-100' id="demo-simple-select-label">Student Name</CustomInputLabel>
                        <CustomSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedStudent}
                            label="Student Name"
                            onChange={handleStudentChange}
                        >
                            {students.map((student) => (
                                <MenuItem key={student.student_id} value={student.student_id}>
                                    {student.student_name}
                                </MenuItem>
                            ))}

                        </CustomSelect>
                    </FormControl>
                </div>
                <div className="col-12 col-lg-6 mb-3">
                    <FormControl fullWidth>
                        <CustomInputLabel className='w-100' id="demo-simple-select-label">Month</CustomInputLabel>
                        <CustomSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedMonth}
                            label="Month"
                            onChange={handleMonthChange}
                        >
                            {months && months.map((month) => (
                                <MenuItem key={month} value={month}>{month}</MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12 col-lg-6 mb-3">
                    <LocalizationProvider dateAdapter={AdapterDayjs} value={selectedYear} >
                        <DatePicker label={"Year"} openTo="year" views={['year']} className='w-100 cust-violet-input-field' onChange={handleYearChange} />
                    </LocalizationProvider>
                </div>
            </div>
            <div className="row buttonsRow">
                <button className='createButton me-3' onClick={submit}>Create</button>
                <button className='cancelButton' onClick={() => { navigate('/app/manage-payments') }}>Cancel</button>
            </div>
        </div>
    )
}

export default ViewRecordFeePayment
