
const baseUrl = "https://impaxify.com/api";
// const baseUrl = "http://localhost:8091/api";


const getAccessToken = () => {
  let token = JSON.parse(localStorage.getItem('token'));
  return token ? token : '';
}

export const loginApi = async (obj) => {
  let res = await fetch(baseUrl+"/login", {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE"
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const changePassword = async (obj) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/changePassword", {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "x-access-token": accessToken
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}

export const forgotPassword = async (obj) => {
  let res = await fetch(baseUrl+"/forgotPassword", {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
// Therapies
export const createTherapy = async (obj) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/createTherapy", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": accessToken
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getTherapy = async (therapy_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getTherapyInfo/"+therapy_id, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}

export const getTherapies = async (account_code) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getTherapies/"+account_code, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}

export const updateTherapy = async (obj, therapy_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(`${baseUrl}/updateTherapy/${therapy_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": accessToken
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const deleteTherapy = async (therapy_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/deleteTherapy/"+therapy_id, {
    method: "DELETE",
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
// Therapist
export const createTherapist = async (obj) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/createTherapist", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": accessToken
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getTherapistInfo = async (therapist_correl_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getTherapistInfo/"+therapist_correl_id, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getTherapists = async (account_code) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getTherapists/"+account_code, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const updateTherapist = async (obj, therapist_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(`${baseUrl}/updateTherapist/${therapist_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": accessToken
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const deleteTherapist = async (therapist_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/deleteTherapist/"+therapist_id, {
    method: "DELETE",
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
// Student
export const createStudent = async (obj) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/createStudent", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": accessToken
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getStudentInfo = async (student_correl_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getStudentInfo/"+student_correl_id, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getStudents = async (account_code) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getStudents/"+account_code, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const updateStudent = async (obj, student_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(`${baseUrl}/updateStudent/${student_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": accessToken
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const deleteStudent = async (student_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/deleteStudent/"+student_id, {
    method: "DELETE",
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
// Accounts
export const createAccountApi = async (obj) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/createAccount", {
    method: "POST",
    headers: {
      "x-access-token": accessToken
    },
    body: obj
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getAllAccounts = async () => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getAllAccounts", {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getAccount = async (account_code) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getAccountInfo/"+account_code, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const updateAccount = async (obj, account_code) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/updateAccount/"+account_code, {
    method: 'PUT',
    headers: {
      "x-access-token": accessToken
    },
    body: obj
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const changeAccountStatus = async (obj) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/changeAccountStatus/"+obj, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}

export const deleteAccount = async (obj) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/deleteAccount/"+obj, {
    method: "DELETE",
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getAllTherapists = async () => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getAllTherapists", {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getAllStudents = async () => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getAllStudents", {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
// Goals
export const createGoal = async (obj) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/createGoal", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": accessToken
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getGoals = async (account_code) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getGoals/"+account_code, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getGoalsByTherapy = async (account_code, therapy_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getGoals/"+account_code+"/"+therapy_id, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getGoalInfo = async (goal_correl_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getGoalInfo/"+goal_correl_id, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getStudentGoalInfo = async (student_id, goal_id, goal_correl_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getStudentGoalInfo/"+student_id+"/"+goal_id+"/"+goal_correl_id, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const updateGoal = async (obj, goal_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(`${baseUrl}/updateGoal/${goal_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": accessToken
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const deleteGoal = async (therapy_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/deleteGoal/"+therapy_id, {
    method: "DELETE",
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
// Announcements
export const createAnnouncement = async (obj) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/createAnnouncement", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": accessToken
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getAnnouncements = async (account_code) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getAnnouncements/"+account_code, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getAnnouncementInfo = async (goal_correl_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getAnnouncementInfo/"+goal_correl_id, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const updateAnnouncement = async (obj, announcement_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(`${baseUrl}/updateAnnouncement/${announcement_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": accessToken
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const deleteAnnouncement = async (announcement_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/deleteAnnouncement/"+announcement_id, {
    method: "DELETE",
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
// Payment fee
export const createFeePayment = async (obj) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/createNewFeePayment", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": accessToken
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getPayments = async (account_code) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getAllPayments/"+account_code, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getPendingPayments = async (account_code) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getPendingPayments/"+account_code, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getPaymentInfo = async (payment_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getPaymentInfo/"+payment_id, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const deleteFeePayment = async (payment_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/deletePayment/"+payment_id, {
    method: "DELETE",
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const updateFeePayment = async (obj, payment_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(`${baseUrl}/updateFeePayment/${payment_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": accessToken
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getMonthlyPayments = async (account_code, month, year) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getMonthlyPayments/"+account_code+"/"+month+"/"+year, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
// Session Progress(Therapy log)
export const createTherapyLog = async (obj) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/createTherapyLog", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": accessToken
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getTherapyLogInfo = async (therapy_log_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getTherapyLogInfo/"+therapy_log_id, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getStudentTherapies = async (student_correl_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getStudentTherapies/"+student_correl_id, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getAllTherapyLogs = async (account_code) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getAllTherapyLogs/"+account_code, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}

export const updateTherapyLog = async (obj, therapy_log_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(`${baseUrl}/updateTherapyLog/${therapy_log_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": accessToken
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const deleteTherapyLog = async (therapy_log_id) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/deleteTherapyLog/"+therapy_log_id, {
    method: "DELETE",
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getTherapistMonthlyTherapyLogs = async (account_code, therapist_id, month, year) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getTherapistMonthlyTherapyLogs/"+account_code+"/"+therapist_id+"/"+month+"/"+year, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}

export const getTherapistLogsByDate = async (account_code, therapist_id, formattedDate) => {
  let accessToken = await getAccessToken();
  let res = await fetch(baseUrl + "/getTherapistLogs/" + account_code + "/" + therapist_id + "/" + formattedDate, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data);
    return data;
  }
  let data = await res.json();
  return data;
}

export const getStudentMonthlyTherapyLogs = async (account_code, student_id, month, year) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getStudentMonthlyTherapyLogs/"+account_code+"/"+student_id+"/"+month+"/"+year, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getStudentDatewiseTherapyLogs = async (account_code, student_id, formattedDate) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getStudentTherapyLogs/"+account_code+"/"+student_id+"/"+formattedDate, {
    headers: {
      "x-access-token": accessToken
    }
  });
  
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getMonthlyTherapyLogs = async (account_code, month, year) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getMonthlyTherapyLogs/"+account_code+"/"+month+"/"+year, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getAccounttherapylogsbydate = async (account_code, formattedDate) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getAccountTherapyLogs/"+account_code+"/"+formattedDate, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data);
    return data;
  }
  let data = await res.json();
  return data;
}
// Dashboard
export const getAdminDashboard = async (account_code, formattedDate, month) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getAdminDashboard/"+account_code+"/"+formattedDate+"/"+month, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data);
    return data;
  }
  let data = await res.json();
  return data;
}
export const getTherapistDashboard = async (account_code, therapist_id, formattedDate, month) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getTherapistDashboard/"+account_code+"/"+therapist_id+"/"+formattedDate+"/"+month, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data);
    return data;
  }
  let data = await res.json();
  return data;
}
export const getStudentDashboard = async (account_code, student_id, formattedDate, month) => {
  let accessToken = await getAccessToken()
  let res = await fetch(baseUrl+"/getStudentDashboard/"+account_code+"/"+student_id+"/"+formattedDate+"/"+month, {
    headers: {
      "x-access-token": accessToken
    }
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data);
    return data;
  }
  let data = await res.json();
  return data;
}
