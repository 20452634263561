import { ArrowBack } from '@mui/icons-material';
import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { deleteAnnouncement, getAnnouncementInfo, updateAnnouncement } from '../../../Api';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

function MobileEditAnnouncements() {
    const navigate = useNavigate();
    const { announcement_id } = useParams();
    const [userInfo, setUserInfo] = useState(null);
    const [content, setContent] = React.useState(null);

    const fetchData = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        setUserInfo(userData);
        let apiData = await getAnnouncementInfo(announcement_id);
        if (apiData.status === "S") {
            setContent(apiData.result_info);
        } else {
            setContent(null)
        }
    }

    const update = async (announcement_id) => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        if (!content.title) {
            Swal.fire({
                text: 'Please enter Title!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.title.length > 255) {
            Swal.fire({
                text: 'Title should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let apiData = await updateAnnouncement({
                title: content.title,
                description: content.description,
            }, announcement_id);
            console.log(apiData);
            if (apiData.status === "S") {
                Swal.fire({
                    text: 'Announcement updated successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-announcements')
                })
            } else if (apiData.status === "E" && apiData.result_code === 404) {
                Swal.fire({
                    text: 'Announcement not found',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-announcements')
                })
            } else if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            } else if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            } else {
                Swal.fire({
                    text: JSON.stringify(apiData),
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
            }
        }
    }

    const delAnnouncement = (announcement_id) => {
        Swal.fire({
            text: `Are you sure you want to delete the announcement?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false,
        }).then(async (res) => {
            if (res.isConfirmed) {
                let apiRes = await deleteAnnouncement(announcement_id);
                console.log(apiRes);
                if (apiRes.status === "S") {
                    Swal.fire({
                        text: `Announcement Deleted`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        fetchData();
                        navigate('/app/manage-announcements')
                    });
                }
            }
        })
    }
    useEffect(() => {
        fetchData();
    }, []);

    const handleGoBack = () => {
        navigate(-1); // Equivalent to history.goBack()
    };
    return (<>
        {content &&
            <div className='mobile-editannouncements'>
                <div className="bg-color">
                    <div className='mb-3'>
                        <ArrowBack className='text-white ms-0' style={{ cursor: 'pointer' }} onClick={handleGoBack} />
                    </div>
                    <div className='text-center'>
                        <h5 className="page-heading text-white mb-2">Edit Announcements</h5>
                    </div>
                </div>
                <div className="contentContainer mt-3">
                    <div className="row mb-3">
                        <div className="col-12 mb-3">
                            <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Title" variant="outlined" autoComplete="off" required value={content.title || ''} onChange={(ev) => {
                                setContent({ ...content, title: ev.target.value })
                            }} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 mb-3">
                            <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Description" variant="outlined" autoComplete="off" required disabled multiline rows={3}
                                value={content.description || ''} onChange={(ev) => {
                                    setContent({ ...content, description: ev.target.value })
                                }} />
                        </div>
                    </div>
                    <div className="row buttonsRow d-flex px-3">
                        <button className='cancelButton me-2 flex-1' onClick={() => { delAnnouncement(content.announcement_id) }}>Delete</button>
                        <button className='createButton flex-1' onClick={() => { update(content.announcement_id) }}>Update</button>
                    </div>
                </div>
            </div>
        }
    </>)
}

export default MobileEditAnnouncements
