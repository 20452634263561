import React from 'react'
import "./createannouncements.scss";
import { ArrowBack } from '@mui/icons-material';
import { TextField } from '@mui/material';
import Swal from 'sweetalert2';
import { createAnnouncement } from '../../../Api';
import { useNavigate } from 'react-router-dom';

function MobileCreateAnnouncements() {
    const navigate = useNavigate();
    const [content, setContent] = React.useState({
        title: "",
        description: "",
        announcement_id: "",

    })
    const submit = async () => {
        if (!content.title) {
            Swal.fire({
                text: 'Please enter Title!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.title.length > 255) {
            Swal.fire({
                text: 'Title should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let data = { ...content };
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            data.account_code = userData.account_code;
            let apiData = await createAnnouncement({ ...data });
            console.log(apiData);
            if (apiData.status === "S") {
                Swal.fire({
                    text: 'Announcement created successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-announcements')
                })
            }
            if (apiData.status === "E") {
                Swal.fire({
                    text: apiData.message,
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        }
    }
    const handleGoBack = () => {
        navigate(-1); // Equivalent to history.goBack()
    };

    return (
        <div className='mobile-createannouncements'>
            <div className="bg-color">
                <div className='mb-3'>
                    <ArrowBack className='text-white' style={{ cursor: 'pointer' }} onClick={handleGoBack} />
                </div>
                <div className='text-center'>
                    <h5 className="page-heading text-white mb-2">Create Announcements</h5>
                </div>
            </div>
            <div className="contentContainer mt-3">
                <div className="row mb-3">
                    <div className="col-12 mb-3">
                        <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Title" variant="outlined" autoComplete="off" required
                            value={content.title} onChange={(ev) => {
                                setContent({ ...content, title: ev.target.value })
                            }} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 mb-3">
                        <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Description" variant="outlined" autoComplete="off" required multiline rows={3}
                            value={content.description} onChange={(ev) => {
                                setContent({ ...content, description: ev.target.value })
                            }} />
                    </div>
                </div>
                <div className="row buttonsRow d-flex px-3">
                    <button className='cancelButton me-2 flex-1' onClick={() => { navigate('/app/manage-announcements') }}>Cancel</button>
                    <button className='createButton flex-1' onClick={() => { submit() }}>Create</button>
                </div>
            </div>
        </div>
    )
}

export default MobileCreateAnnouncements
