import React, { useEffect, useState } from 'react'
import "./edittherapy.scss"
import { TextField } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { deleteTherapy, getTherapy, updateTherapy } from '../../../Api';
import { useNavigate, useParams } from 'react-router-dom';

function MobileEditTherapy() {
    const navigate = useNavigate();
    const { therapy_id } = useParams();
    const [userInfo, setUserInfo] = useState(null);

    const [content, setContent] = React.useState({
        therapy_name: "",
    })

    const fetchData = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        setUserInfo(userData);
        let apiData = await getTherapy(therapy_id);
        if (apiData.status === "S") {
            setContent(apiData.result_info)
        } else {
            setContent({
                therapy_name: "",
            })
        }
    }
    const update = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        if (!content.therapy_name) {
            Swal.fire({
                text: 'Please provide Therapy name!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.therapy_name.length > 255) {
            Swal.fire({
                text: 'Therapy name should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let apiData = await updateTherapy({
                account_code: userData.account_code,
                therapy_name: content.therapy_name,
            }, therapy_id);
            console.log(apiData);
            if (apiData.status === "S") {
                Swal.fire({
                    text: 'Therapy updated successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-therapies')
                })
            } else if (apiData.status === "E" && apiData.result_code === 404) {
                Swal.fire({
                    text: 'Therapy not found',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-therapies')
                })
            } else if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            } else if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            } else {
                Swal.fire({
                    text: JSON.stringify(apiData),
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
            }
        }
    }
    const delTherapy = (therapy_id) => {
        Swal.fire({
            text: `Are you sure you want to delete the therapy?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false,
        }).then(async (res) => {
            if (res.isConfirmed) {
                let apiRes = await deleteTherapy(therapy_id);
                console.log(apiRes);
                if (apiRes.status === "S") {
                    Swal.fire({
                        text: `Therapy Deleted`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        navigate('/app/manage-therapies');
                        fetchData();

                    });
                }
            }
        })
    }
    useEffect(() => {
        fetchData();
    }, [])
    const handleGoBack = () => {
        navigate('/app/manage-therapies'); // Equivalent to history.goBack()
    };
    return (
        <div className='d-block d-lg-none mobile-edittherapy'>
            <div className="bg-color">
                <div className='mb-3'>
                    <ArrowBack className='text-white' style={{ cursor: 'pointer' }} onClick={handleGoBack} />
                </div>
                <div className='text-center'>
                    <h5 className="page-heading text-white mb-2">Edit Therapy</h5>
                </div>
            </div>
            <div className='contentContainer mt-3'>
                <div className="row mb-3">
                    <div className="col-12 col-lg-6 mb-3 mt-3">
                        <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Therapy Name" variant="outlined" autoComplete="off" required value={content.therapy_name || ''} onChange={(ev) => {
                            setContent({ ...content, therapy_name: ev.target.value })
                        }} />
                    </div>
                </div>
                <div className="row buttonsRow d-flex px-3">
                    <button className='cancelButton me-2 flex-1' onClick={() => { handleGoBack() }}>Cancel</button>
                    {/* <button className='cancelButton me-2 flex-1' onClick={() => { delTherapy(content.therapy_id) }}>Delete</button> */}
                    <button className='createButton flex-1' onClick={() => { update(content.therapy_id) }}>Update</button>
                </div>
            </div>

        </div>
    )
}

export default MobileEditTherapy
