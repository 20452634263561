import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import "./managestudents.scss"
import { TextField } from '@mui/material';
import { ChevronRight, ControlPoint, KeyboardArrowLeft, KeyboardArrowRight, Search } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { deleteStudent, getStudents } from '../../../Api';
import MobileFooter from '../../../components/mobile-footer/MobileFooter';

function ManageStudents() {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [totalPages, setTotalPages] = useState(null);
    const [content, setContent] = React.useState([]);

    const filteredData = [...content].filter(row =>
        Object.values(row).some(value =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset current page when the search query changes
    };

    const paginatedData = [...filteredData].slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const edit = (student_correl_id) => {
        navigate('/app/edit-student/' + student_correl_id);
    }
    const delStudent = (student_id) => {
        Swal.fire({
            text: `Are you sure you want to delete the student?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false,
        }).then(async (res) => {
            if (res.isConfirmed) {
                let apiRes = await deleteStudent(student_id);
                console.log(apiRes);
                if (apiRes.status === "S") {
                    Swal.fire({
                        text: `Student Deleted`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        fetchData();
                    });
                }
            }
        })
    }

    const fetchData = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let acc_code = userData.account_code;
        let apiData = await getStudents(acc_code);
        apiData.status === "S" ? setContent(apiData.result_info) : setContent([]);
        apiData.status === "S" ? setTotalPages(Math.ceil([...apiData.result_info].length / pageSize)) : setTotalPages(0);
        if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
            Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        }
        if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
            Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        }
    }

    useEffect(() => {
        (async () => {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let acc_code = userData.account_code;
            let apiData = await getStudents(acc_code);
            apiData.status === "S" ? setContent(apiData.result_info) : setContent([]);
            apiData.status === "S" ? setTotalPages(Math.ceil([...apiData.result_info].length / pageSize)) : setTotalPages(0);
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        })();
    }, [navigate])
    return (
        <>
            <div className='manageContainer'>
                <h5 className='page-heading'>Manage Students</h5>
                <div className='searchbar-div mb-4'>
                    <div className='searchbar'>
                        <TextField id="standard-basic" label="Search" variant="standard" value={searchQuery} onChange={handleSearchChange} />
                    </div>
                    <Link to="/app/create-student">
                        <div className="buttonCreate">
                            <button className='create'>+ Create</button>
                        </div>
                    </Link>
                </div>
                <div className="card manage-card">
                    <div className="tableContainer common-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Parent Name</th>
                                    <th>Phone</th>
                                    <th className='col-width'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedData && (paginatedData.length > 0) && paginatedData.map((row, index) => (
                                    <tr key={index}>
                                        <td>{row.student_name}</td>
                                        <td>{row.parent_name}</td>
                                        <td>{row.parent_mobile}</td>
                                        <td className='col-width'>
                                            <button className="btn createButton px-4 me-2" onClick={() => { edit(row.student_correl_id) }}>Edit</button>
                                            <button className="btn cancelButton px-3" onClick={() => { delStudent(row.student_id) }}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-end align-items-center">
                            <button className="btn btn-light me-2" onClick={previousPage} disabled={currentPage === 1}>
                                <KeyboardArrowLeft />
                            </button>
                            <span>Page {currentPage} of {totalPages}</span>
                            <button className="btn btn-light ms-2" onClick={nextPage} disabled={currentPage === totalPages}>
                                <KeyboardArrowRight />
                            </button>
                        </div>
                    </div>

                </div>

            </div>

        </>

    )
}

export default ManageStudents
