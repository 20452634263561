import { FormatListBulleted, Home, Group } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { FaHome, FaUsers, FaEllipsisH, FaUser } from 'react-icons/fa';
import { IoPerson, IoHome } from "react-icons/io5";
import { Link, useLocation } from 'react-router-dom';

const MobileFooter = () => {
    const [isRole, setIsRole] = useState(null);
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path ? '#8267ed' : '#000';
    };
    useEffect(() => {
        let userRole = JSON.parse(localStorage.getItem('role'));
        setIsRole(userRole);
        // console.log(userRole);
    }, []);
    return (
        <div className="container-fluid bg-white mobile-footer">
            {+isRole === 2 && (
                <div className="row text-center">
                    <div className="col py-1">
                        <Link to='/app/dashboard' className="text-decoration-none" style={{ color: isActive('/app/dashboard') }}>
                            <Home size={20} />
                            <div>Home</div>
                        </Link>
                    </div>
                    <div className="col py-1">
                        <Link to='/app/manage-students' className="text-decoration-none" style={{ color: isActive('/app/manage-students') }} >
                            <Group size={20} />
                            <div>Students</div>
                        </Link>
                    </div>
                    <div className="col py-1">
                        <Link to='/app/sessions-feedback' className="text-decoration-none" style={{ color: isActive('/app/sessions-feedback') }}>
                            <FormatListBulleted size={20} />
                            <div>Sessions</div>
                        </Link>
                    </div>
                    <div className="col py-1">
                        <Link to='/app/morepage' className="text-decoration-none" style={{ color: isActive('/app/morepage') }}>
                            <FaEllipsisH size={20} />
                            <div>More</div>
                        </Link>
                    </div>
                </div>
            )}
            {+isRole === 3 && (
                <div className="row text-center">
                    <div className="col py-1">
                        <Link to='/app/dashboard' className="text-decoration-none" style={{ color: isActive('/app/dashboard') }}>
                            <Home size={20} />
                            <div>Home</div>
                        </Link>
                    </div>

                    <div className="col py-1">
                        <Link to='/app/sessions-feedback' className="text-decoration-none" style={{ color: isActive('/app/sessions-feedback') }}>
                            <FormatListBulleted size={20} />
                            <div>Sessions</div>
                        </Link>
                    </div>
                    <div className="col py-1">
                        <Link to='/app/profile' className="text-decoration-none" style={{ color: isActive('/app/profile') }}>
                            <IoPerson size={20} />
                            <div>Profile</div>
                        </Link>
                    </div>
                    <div className="col py-1">
                        <Link to='/app/morepage' className="text-decoration-none" style={{ color: isActive('/app/morepage') }}>
                            <FaEllipsisH size={20} />
                            <div>More</div>
                        </Link>
                    </div>
                </div>
            )}
            {+isRole === 4 && (
                <div className="row text-center">
                    <div className="col py-1">
                        <Link to='/app/dashboard' className="text-decoration-none" style={{ color: isActive('/app/dashboard') }}>
                            <Home size={20} />
                            <div>Home</div>
                        </Link>
                    </div>

                    <div className="col py-1">
                        <Link to='/app/sessions-feedback' className="text-decoration-none" style={{ color: isActive('/app/sessions-feedback') }}>
                            <FormatListBulleted size={20} />
                            <div>Sessions</div>
                        </Link>
                    </div>
                    <div className="col py-1">
                        <Link to='/app/profile' className="text-decoration-none" style={{ color: isActive('/app/profile') }}>
                            <IoPerson size={20} />
                            <div>Profile</div>
                        </Link>
                    </div>
                    <div className="col py-1">
                        <Link to='/app/morepage' className="text-decoration-none" style={{ color: isActive('/app/morepage') }}>
                            <FaEllipsisH size={20} />
                            <div>More</div>
                        </Link>
                    </div>
                </div>
            )}

        </div>
    );
};

export default MobileFooter;