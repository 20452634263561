import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import "./createsessions.scss"
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField, useStepContext } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { createTherapyLog, getGoalInfo, getGoalsByTherapy, getStudentGoalInfo, getStudentTherapies, getStudents } from '../../../Api';
import { ArrowBack } from '@mui/icons-material';

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    width: '100%',
    color: '#000',
    '&.Mui-focused': {
        color: '#000',
    },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#8267ed',
    },
}));
const ColoredCheckbox = styled(Checkbox)(({ theme }) => ({
    color: theme.palette.main,
    '&.Mui-checked': {
        color: '#8267ed',
        borderColor: '#8267ed',
    },
}));
function CreateSessionFeedback() {
    const navigate = useNavigate();
    const [students, setStudents] = useState([]);
    const [therapies, setTherapies] = useState([]);
    const [goals, setGoals] = useState([]);
    const [goalInfo, setGoalInfo] = useState(null);

    const [content, setContent] = useState({
        date: "",
        student_id: "",
        therapy_id: "",
        goal_id: "",
        activities: [],
        comments: "",
    })

    useEffect(() => {
        const fetchData = async () => {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            setUserInfo(userData);

            let apiData = await getStudents(userData.account_code);
            if (apiData.status === "S") {
                setStudents(apiData.result_info);
            } else {
                setStudents(null);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchTherapies = async () => {
            if (content.student_id) {
                const selectedStudent = students.find(student => student.student_id === content.student_id);
                if (selectedStudent) {
                    let studentTherapiesData = await getStudentTherapies(selectedStudent.student_correl_id);
                    if (studentTherapiesData.status === "S") {
                        setTherapies(studentTherapiesData.result_info);
                    } else {
                        setTherapies([]);
                    }
                }
            }
        };
        fetchTherapies();
    }, [content.student_id, students]);

    useEffect(() => {
        const fetchGoals = async () => {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            if (content.therapy_id) {
                let goalData = await getGoalsByTherapy(userData.account_code, content.therapy_id);
                if (goalData.status === "S") {
                    setGoals(goalData.result_info);
                } else {
                    setGoals([]);
                }
            }
        };

        fetchGoals();
    }, [content.therapy_id, therapies]);

    useEffect(() => {
        const fetchGoalInfo = async () => {
            if (content.goal_id) {
                const selectedStudent = students.find(student => student.student_id === content.student_id);
                const selectedGoal = goals.find(goal => goal.goal_id === content.goal_id);
                if (selectedGoal) {
                    let goalInfoData = await getStudentGoalInfo(selectedStudent.student_id, selectedGoal.goal_id, selectedGoal.goal_correl_id);
                    if (goalInfoData.status === "S") {
                        setGoalInfo(goalInfoData.result_info);
                    } else {
                        setGoalInfo(null);
                    }
                }
            }
        };

        fetchGoalInfo();
    }, [content.goal_id, goals]);


    const submit = async () => {
        if (!content.date) {
            Swal.fire({
                text: 'Please Select Date!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.activities.length === 0) {
            Swal.fire({
                text: 'Please select at least one activity!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let data = { ...content, account_code: userData.account_code, activity_id: content.activities.join(","), therapist_id: localStorage.getItem('therapist_id' || '') };
            console.log(data);
            let apiData = await createTherapyLog({ ...data });
            console.log(apiData);
            if (apiData.status === "S") {
                Swal.fire({
                    text: 'Session Feedback created successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/sessions-feedback')
                })
            }
            if (apiData.status === "E") {
                Swal.fire({
                    text: apiData.message,
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        }
    }

    const handleStudentChange = (event) => {
        const selectedStudentId = event.target.value;
        setContent({
            ...content,
            student_id: selectedStudentId,
            therapy_id: '', // Reset therapy_id, goal_id and activities when student changes
            goal_id: '',
            activities: []
        });

        setTherapies([]);
        setGoals([]);
        setGoalInfo(null);
    };

    const handleTherapyChange = (event) => {
        const selectedTherapyId = event.target.value;
        setContent({
            ...content,
            therapy_id: selectedTherapyId,
            goal_id: '', // Reset goal_id and activities when therapy changes
            activities: []
        });

        setGoals([]);
        setGoalInfo(null);
    };

    const handleGoalChange = (event) => {
        const selectedGoalId = event.target.value;
        setContent({
            ...content,
            goal_id: selectedGoalId,
            activities: [] // Reset activities when goal changes
        });

        setGoalInfo(null);
    };

    const handleActivityChange = (event, activityId) => {
        const isChecked = event.target.checked;

        // Update activities array based on checkbox state
        if (isChecked) {
            // Add activityId to activities array
            setContent((prevContent) => ({
                ...prevContent,
                activities: [...prevContent.activities, activityId]
            }));
        } else {
            // Remove activityId from activities array
            setContent((prevContent) => ({
                ...prevContent,
                activities: prevContent.activities.filter((id) => id !== activityId)
            }));
        }
    };

    const [userInfo, setUserInfo] = useState(null);
    const handleGoBack = () => {
        navigate(-1); // Equivalent to history.goBack()
    };
    return (
        <div className='createContainer'>
            <h5 className='page-heading'> Session Feedback </h5>
            <div className="row mb-lg-3">
                <div className="col-12 col-lg-6 mb-3">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label="Date *" className='w-100 cust-violet-input-field' defaultValue={dayjs(content.date)}
                            value={dayjs(content.date)}
                            onChange={(ev) => {
                                let date = ev?.$d;
                                setContent({ ...content, date: dayjs(date).format('YYYY-MM-DD') })
                            }} />
                    </LocalizationProvider>
                </div>

                <div className="col-12 col-lg-6 mb-3">
                    <FormControl fullWidth required>
                        <CustomInputLabel id="demo-simple-select-label">Student Name</CustomInputLabel>
                        <CustomSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={content.student_id}
                            label="Student Name"
                            onChange={handleStudentChange}
                        >
                            {students.map((student) => (
                                <MenuItem key={student.student_id} value={student.student_id}>
                                    {student.student_name}
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                </div>
            </div>
            <div className="row mb-lg-3">
                <div className="col-12 col-lg-6 mb-3">
                    {content.student_id && (
                        <FormControl fullWidth required>
                            <CustomInputLabel className='w-100 cust-violet-input-field' id="therapy-select-label">Therapy</CustomInputLabel>
                            <CustomSelect
                                labelId="therapy-select-label"
                                id="therapy-select"
                                value={content.therapy_id}
                                label="Therapy"
                                required
                                onChange={handleTherapyChange}

                            >
                                {therapies.map((therapy) => (
                                    <MenuItem key={therapy.therapy_id} value={therapy.therapy_id}>
                                        {therapy.therapy_name}
                                    </MenuItem>
                                ))}

                            </CustomSelect>
                        </FormControl>
                    )}
                </div>
                <div className="col-12 col-lg-6">
                    {content.therapy_id && (
                        <FormControl fullWidth required>
                            <CustomInputLabel className='w-100 cust-violet-input-field' id="goal-select-label">Goal</CustomInputLabel>
                            <CustomSelect
                                labelId="goal-select-label"
                                id="goal-select"
                                value={content.goal_id}
                                label="Goal"
                                required
                                onChange={handleGoalChange}
                            >
                                {goals.map((goal) => (
                                    <MenuItem key={goal.goal_id} value={goal.goal_id}>
                                        {goal.goal_name}
                                    </MenuItem>
                                ))}
                            </CustomSelect>
                        </FormControl>
                    )}
                </div>

                <div className='col-12'>
                    {content.goal_id && goalInfo && (
                        <div>
                            {goalInfo.length > 0 ? (
                                <>
                                    {goalInfo.map((activity) => (
                                        <div key={activity.activity_id} style={{ display: 'flex', alignItems: 'center' }}>
                                            <ColoredCheckbox className='checked'
                                                checked={content.activities.includes(activity.activity_id)}
                                                onChange={(event) => handleActivityChange(event, activity.activity_id)}
                                            />
                                            <ListItemText primary={activity.activity_name} />
                                        </div>
                                    ))}
                                    <div className="col-12 mt-3 mb-3">
                                        <TextField
                                            className="w-100 cust-violet-input-field"
                                            id="outlined-basic"
                                            label="Comments"
                                            variant="outlined"
                                            multiline
                                            rows={3}
                                            autoComplete="off"
                                            value={content.comments}
                                            onChange={(ev) => {
                                                setContent({ ...content, comments: ev.target.value })
                                            }}
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className='reminder-text'>No Pending Activities for this Goal</div>
                            )}
                        </div>
                    )}
                </div>

            </div>
            <div className="row buttonsRow">
                <button className='createButton me-3' onClick={submit}>Create</button>
                <button className='cancelButton' onClick={() => { navigate('/app/sessions-feedback') }}>Cancel</button>
            </div>

        </div>


    )
}

export default CreateSessionFeedback
