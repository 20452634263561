import React, { useEffect, useState } from 'react'
import "./edittherapy.scss"
import { TextField } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2';
import { deleteTherapy, getTherapy, updateTherapy } from '../../../Api';
import { ArrowBack } from '@mui/icons-material';

function EditTherapy() {
    const navigate = useNavigate();
    const { therapy_id } = useParams();
    const [userInfo, setUserInfo] = useState(null);

    const [content, setContent] = React.useState({
        therapy_name: "",
    })

    const fetchData = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        setUserInfo(userData);
        let apiData = await getTherapy(therapy_id);
        if (apiData.status === "S") {
            setContent(apiData.result_info)
        } else {
            setContent({
                therapy_name: "",
            })
        }
    }
    const update = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        if (!content.therapy_name) {
            Swal.fire({
                text: 'Please provide Therapy name!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.therapy_name.length > 255) {
            Swal.fire({
                text: 'Therapy name should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let apiData = await updateTherapy({
                account_code: userData.account_code,
                therapy_name: content.therapy_name,
            }, therapy_id);
            console.log(apiData);
            if (apiData.status === "S") {
                Swal.fire({
                    text: 'Therapy updated successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-therapies')
                })
            } else if (apiData.status === "E" && apiData.result_code === 404) {
                Swal.fire({
                    text: 'Therapy not found',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-therapies')
                })
            } else if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            } else if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            } else {
                Swal.fire({
                    text: JSON.stringify(apiData),
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
            }
        }
    }
    const delTherapy = (therapy_id) => {
        Swal.fire({
            text: `Are you sure you want to delete the therapy?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false,
        }).then(async (res) => {
            if (res.isConfirmed) {
                let apiRes = await deleteTherapy(therapy_id);
                console.log(apiRes);
                if (apiRes.status === "S") {
                    Swal.fire({
                        text: `Therapy Deleted`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        fetchData();
                    });
                }
            }
        })
    }
    useEffect(() => {
        fetchData();
    }, [])
    const handleGoBack = () => {
        navigate(-1); // Equivalent to history.goBack()
    };
    return (
        <div className='editContainer'>
            <h5 className='page-heading'>Edit Therapy</h5>
            <div className="row mb-3">
                <div className="col-12 mb-3">
                    <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Name" variant="outlined" autoComplete="off" required value={content.therapy_name || ''} onChange={(ev) => {
                        setContent({ ...content, therapy_name: ev.target.value })
                    }} />
                </div>

            </div>
            <div className="row buttonsRow">
                <button className='createButton me-3' onClick={() => { update() }}>Update</button>
                <button className='cancelButton' onClick={() => { navigate('/app/manage-therapies') }}>Cancel</button>
            </div>
        </div>


    )
}

export default EditTherapy
