import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import "./editpayments.scss"
import { styled } from '@mui/material/styles';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
import Swal from 'sweetalert2';
import { deleteFeePayment, getPaymentInfo, getStudents, updateFeePayment } from '../../../Api';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ArrowBack } from '@mui/icons-material';

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    width: '100%',
    color: '#000',
    '&.Mui-focused': {
        color: '#000',
    },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#8267ed',
    },
}));
function EditRecordFeePayment() {
    const navigate = useNavigate();
    const { payment_id } = useParams();
    const [selectedStudent, setSelectedStudent] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedValue, setSelectedValue] = useState('yes');
    const [students, setStudents] = useState([]);
    const [userInfo, setUserInfo] = useState(null);

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const monthIndex = (name) => {
        return months.indexOf(name);
    }
    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
        setContent({ ...content, is_paid: event.target.value === 'yes' ? 'Y' : 'N' });
    };
    const [content, setContent] = React.useState(null)

    const handleStudentChange = (event) => {
        setSelectedStudent(event.target.value);
    };
    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleYearChange = (date) => {
        setSelectedYear(date.year());
    };


    const fetchData = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        setUserInfo(userData);
        let studentData = await getStudents(userData.account_code);
        if (studentData.status === "S") {
            setStudents(studentData.result_info);
        } else {
            setStudents([])
        }
        let apiData = await getPaymentInfo(payment_id);

        if (apiData.status === "S") {
            let year = dayjs(`${apiData.result_info.year}-01-01`);
            console.log(year);
            setContent(apiData.result_info);
            setSelectedStudent(apiData.result_info.student_id);
            setSelectedMonth(months[apiData.result_info.month]);
            setSelectedYear(year.year());
            setSelectedValue(apiData.result_info.is_paid === 'Y' ? 'yes' : 'no');
        } else {
            setContent(null)
        }

    }
    const update = async (payment_id) => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let apiData = await updateFeePayment({
            account_code: userData.account_code,
            student_id: selectedStudent,
            month: monthIndex(selectedMonth),
            year: selectedYear,
        }, payment_id);
        console.log(apiData);
        if (apiData.status === "S") {
            Swal.fire({
                text: 'Record updated successfully',
                showConfirmButton: false,
                icon: 'success',
                timer: 3000,
            }).then(() => {
                navigate('/app/manage-payments')
            })
        } else if (apiData.status === "E" && apiData.result_code === 404) {
            Swal.fire({
                text: 'Record not found',
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            }).then(() => {
                navigate('/app/manage-payments')
            })
        } else if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
            Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        } else if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
            Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        } else {
            Swal.fire({
                text: JSON.stringify(apiData),
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            })
        }
    }
    const delPayment = (payment_id) => {
        Swal.fire({
            text: `Are you sure you want to delete the payment?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false,
        }).then(async (res) => {
            if (res.isConfirmed) {
                let apiRes = await deleteFeePayment(payment_id);
                console.log(apiRes);
                if (apiRes.status === "S") {
                    Swal.fire({
                        text: `Payment Deleted`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        fetchData();
                    });
                }
            }
        })
    }
    useEffect(() => {
        fetchData();
    }, [payment_id]);



    return (
        <>
            {content &&
                <div className='editContainer'>
                    <h5 className='page-heading'>Edit Payment</h5>
                    <div className="row mb-lg-3">
                        <div className="col-12 col-lg-6 mb-3">
                            <FormControl fullWidth>
                                <CustomInputLabel className='w-100' id="demo-simple-select-label">Student Name</CustomInputLabel>
                                <CustomSelect
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedStudent || ''}
                                    label="Student Name"
                                    onChange={handleStudentChange}
                                >
                                    {students.map((student) => (
                                        <MenuItem key={student.student_id} value={student.student_id}>
                                            {student.student_name}
                                        </MenuItem>
                                    ))}

                                </CustomSelect>
                            </FormControl>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                            <FormControl fullWidth>
                                <CustomInputLabel className='w-100' id="demo-simple-select-label">Month</CustomInputLabel>
                                <CustomSelect
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedMonth}
                                    label="Month"
                                    onChange={handleMonthChange}
                                >
                                    {months && months.map((month) => (
                                        <MenuItem key={month} value={month}>{month}</MenuItem>
                                    ))}
                                </CustomSelect>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 col-lg-6 mb-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DatePicker label={"Year"} format='YYYY' views={['year']} className='w-100 cust-violet-input-field' value={dayjs().year(selectedYear)}
                                    onChange={(ev) => handleYearChange(ev)} />
                            </LocalizationProvider>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                            <h5 className='mb-0'>Paid</h5>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="yes"
                                    name="radio-buttons-group"
                                    value={selectedValue}
                                    onChange={handleRadioChange}
                                    row
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row buttonsRow">
                        <button className='createButton me-3' onClick={() => { update(content.payment_id) }}>Update</button>
                        <button className='cancelButton' onClick={() => { navigate('/app/manage-payments') }}>Cancel</button>
                    </div>
                </div>
            }
        </>

    )
}

export default EditRecordFeePayment
