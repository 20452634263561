import React, { useEffect, useState } from 'react';
import AdminProfile from '../../components/admin-profile/AdminProfile';
import TherapistProfile from '../../components/therapist-profile/TherapistProfile';
import StudentProfile from '../../components/student-profile/StudentProfile';
import MobileAdminProfile from '../../components/admin-profile/MobileAdminProfile';
import MobileTherapistProfile from '../../components/therapist-profile/MobileTherapistProfile';
import MobileStudentProfile from '../../components/student-profile/MobileStudentProfile';

function Profile() {
  const [role, setRole] = useState(null);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const storedUserData = JSON.parse(localStorage.getItem('userData') || '{}');
      const storedRole = JSON.parse(localStorage.getItem('role'));
      setUserData({ ...storedUserData });
      setRole(storedRole);
      console.log(storedRole);
    };

    fetchData();
  }, []);

  return (
    <>
      {window.innerWidth >= 992 && +role === 2 &&
        <AdminProfile accountCode={userData.account_code} userData={userData} />
      }
      {window.innerWidth < 992 && +role === 2 &&
        <MobileAdminProfile accountCode={userData.account_code} userData={userData} />
      }
      {window.innerWidth >= 992 && +role === 3 &&
        <TherapistProfile accountCode={userData.account_code} userData={userData} />
      }
      {window.innerWidth < 992 && +role === 3 &&
        <MobileTherapistProfile accountCode={userData.account_code} userData={userData} />
      }
      {window.innerWidth >= 992 && +role === 4 &&
        <StudentProfile accountCode={userData.account_code} userData={userData} />
      }
      {window.innerWidth < 992 && +role === 4 &&
        <MobileStudentProfile accountCode={userData.account_code} userData={userData} />
      }
    </>
  )
}

export default Profile;