import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import "./sessions.scss"
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { deleteTherapyLog, getAccounttherapylogsbydate, getMonthlyTherapyLogs, getStudentDatewiseTherapyLogs, getStudentMonthlyTherapyLogs, getTherapistLogsByDate, getTherapistMonthlyTherapyLogs } from '../../../Api';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function SessionsFeedback() {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [totalPages, setTotalPages] = useState(null);
    const [content, setContent] = useState([]);
    const [activeTab, setActiveTab] = useState('today');
    const [role, setRole] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(dayjs());
    const [selectedYear, setSelectedYear] = useState(dayjs().year());

    const filteredData = [...content].filter(row =>
        Object.values(row).some(value =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const paginatedData = [...filteredData].slice((currentPage - 1) * pageSize, currentPage * pageSize);;

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const edit = therapy_log_id => {
        navigate('/app/edit-session-feedback/' + therapy_log_id);
    };

    const view = therapy_log_id => {
        navigate('/app/view-session-feedback/' + therapy_log_id);
    };

    const delSession = (therapy_log_id) => {
        Swal.fire({
            text: 'Are you sure you want to delete?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false,
        }).then(async res => {
            if (res.isConfirmed) {
                let apiRes = await deleteTherapyLog(therapy_log_id);
                if (apiRes.status === "S") {
                    Swal.fire({
                        text: 'Session feedback deleted successfully!',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        // fetchData();
                    });
                }
            }
        });
    };

    const fetchRecords = useCallback(async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let userRole = JSON.parse(localStorage.getItem('role'));
        setRole(userRole);
        let apiRes;
        if (activeTab === 'today') {
            let currentDate = new Date();
            let formattedDate = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate()

            if (+userRole === 2) {
                apiRes = await getAccounttherapylogsbydate(userData.account_code, formattedDate);
            }
            if (+userRole === 3) {
                apiRes = await getTherapistLogsByDate(userData.account_code, userData.therapist_id, formattedDate);
            }
            if (+userRole === 4) {
                apiRes = await getStudentDatewiseTherapyLogs(userData.account_code, userData.student_id, formattedDate);
            }
        }
        if (activeTab === 'monthly') {
            const month = selectedMonth.month();
            const year = selectedYear;
            if (+userRole === 2) {
                apiRes = await getMonthlyTherapyLogs(userData.account_code, month, year);
            }
            if (+userRole === 3) {
                apiRes = await getTherapistMonthlyTherapyLogs(userData.account_code, userData.therapist_id, month, year)
            }
            if (+userRole === 4) {
                apiRes = await getStudentMonthlyTherapyLogs(userData.account_code, userData.student_id, month, year)
            }
        }
        let data = await apiRes;
        console.log(data);

        if (data.status === 'S') {
            setContent(data.result_info);
            setTotalPages(Math.ceil(data.result_info.length / pageSize));
        } else if (data.result_code === 404) {
            setContent([]);
            setTotalPages(0);
        } else {
            setContent([]);
            setTotalPages(0);
            Swal.fire({
                icon: 'error',
                title: 'Something went wrong!',
            });
        }

    }, [activeTab, selectedMonth, selectedYear]);

    useEffect(() => {
        fetchRecords()
    }, [fetchRecords])

    return (
        <div className='manageContainer'>
            <h5 className='page-heading'>Sessions Feedback</h5>
            <div className='searchbar-div mb-4'>
                <div className='searchbar'>
                    <TextField
                        id="standard-basic"
                        label="Search"
                        variant="standard"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </div>
                <div className='d-flex align-items-center'>
                    {activeTab === 'monthly' && (
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DatePicker label={'Month and Year'} views={['month', 'year']}
                                className='me-3 cust-violet-input-field'
                                value={selectedMonth}
                                onChange={(newValue) => setSelectedMonth(newValue)} />
                        </LocalizationProvider>
                    )}
                    {role === '3' && (
                        <Link to="/app/create-session-feedback">
                            <div className="buttonCreate">
                                <button className='create'>+ Create</button>
                            </div>
                        </Link>
                    )}
                </div>
            </div>
            <div className="card manage-card">
                <div className="tableContainer common-table">
                    <div className='text-center justify-content-center mb-3'>
                        <button
                            className={`tab-button ${activeTab === 'today' ? 'active' : ''}`}
                            onClick={() => {
                                setActiveTab('today');
                            }}
                        >
                            Today
                        </button>
                        <button
                            className={`tab-button ${activeTab === 'monthly' ? 'active' : ''}`}
                            onClick={() => {
                                setActiveTab('monthly');
                            }}
                        >
                            Monthly
                        </button>
                    </div>

                    <table className="table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Student Name</th>
                                <th>Therapy</th>
                                <th className='col-width'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.length > 0 ? (
                                paginatedData.map((row, index) => (
                                    <tr key={index}>
                                        <td>{row.date}</td>
                                        <td>{row.student_name}</td>
                                        <td>{row.therapy_name}</td>
                                        <td className='col-width'>
                                            {(role === '4' || role === '2') &&
                                                <button className="createButton px-4" onClick={() => view(row.therapy_log_id)}>View</button>
                                            } 
                                            
                                            {role === '3' && <>
                                                <button className="createButton px-4 me-2" onClick={() => view(row.therapy_log_id)}>View</button>
                                                <button className="cancelButton px-3" onClick={() => delSession(row.therapy_log_id)}>Delete</button>
                                            </>}
                                            
                                                {/* <>
                                                    <button className="createButton px-4 me-2" onClick={() => edit(row.therapy_log_id)}>Edit</button>
                                                    <button className="cancelButton px-3" onClick={() => delSession(row.therapy_log_id)}>Delete</button>
                                                </> */}
                                            
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className="text-center">No records found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-end align-items-center">
                        <button className="btn btn-light me-2" onClick={previousPage} disabled={currentPage === 1}>
                            <KeyboardArrowLeft />
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button className="btn btn-light ms-2" onClick={nextPage} disabled={currentPage === totalPages}>
                            <KeyboardArrowRight />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SessionsFeedback;
