import React, { useEffect, useState } from 'react'
import "./managepayments.scss"
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { deleteFeePayment, getMonthlyPayments, getPayments } from '../../../Api';
import { ArrowBack, ChevronRight, ControlPoint } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function MobilePayments() {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [totalPages, setTotalPages] = useState(null);
    const [activeTab, setActiveTab] = useState('paid');
    const [content, setContent] = React.useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const monthName = (num) => {
        return months[num];
    }

    const filteredData = [...content].filter(row =>
        Object.values(row).some(value =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset current page when the search query changes
    };

    const paginatedData = [...filteredData].slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const edit = (payment_id) => {
        navigate('/app/edit-payments/' + payment_id);
    }
    const delPayment = (payment_id) => {
        Swal.fire({
            text: `Are you sure you want to delete the payment?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false,
        }).then(async (res) => {
            if (res.isConfirmed) {
                let apiRes = await deleteFeePayment(payment_id);
                console.log(apiRes);
                if (apiRes.status === "S") {
                    Swal.fire({
                        text: `Payment Deleted`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        fetchData();
                    });
                }
            }
        })
    }

    const fetchData = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let acc_code = userData.account_code;
        let apiData;
        // if(activeTab === "paid"){
        apiData = await getPayments(acc_code);
        // }
        // if(activeTab === "dues"){
        //     apiData = await getPendingPayments(acc_code);
        // }
        if (apiData && apiData.status === "S") {
            setContent(apiData.result_info);
            setTotalPages(Math.ceil([...apiData.result_info].length / pageSize));
        } else {
            setContent([]);
            setTotalPages(0);
        }

        // apiData.status === "S" ?  setContent(apiData.result_info) : setContent([]);
        // apiData.status === "S" ? setTotalPages(Math.ceil([...apiData.result_info].length / pageSize)): setTotalPages(0);
        if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
            Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        }
        if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
            Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        }
    }
    useEffect(() => {
        const fetchPayments = async () => {
            if (selectedDate) {
                let role = 2;
                const userData = JSON.parse(localStorage.getItem('userData') || '{}');
                const acc_code = userData.account_code;
                let apiData;
                if (role === 2) {
                    apiData = await getMonthlyPayments(acc_code, selectedDate.getMonth(), selectedDate.getFullYear());

                }

                if (apiData.status === "S") {
                    setContent(apiData.result_info);
                    const totalPagesCount = Math.ceil(apiData.result_info.length / pageSize);
                    setTotalPages(totalPagesCount > 0 ? totalPagesCount : 1);
                } else {
                    setContent([]);
                    setTotalPages(0);


                }
            }
        };

        fetchPayments();
    }, [selectedDate, navigate]);

    // useEffect(() => {
    //    fetchData()
    // }, [navigate])
    const handleGoBack = () => {
        navigate(-1); // Equivalent to history.goBack()
    };

    return (
        <div className='mobile-payments'>
            <>
                <div className='header-container'>
                    <div className='d-flex justify-content-between'>
                        <ArrowBack className='text-white icon-position' onClick={handleGoBack} />
                        {activeTab === 'paid' && (
                            <Link to="/app/view-record-fee-payment" className='text-decoration-none'>
                                <ControlPoint className='create' />
                            </Link>
                        )}

                    </div>
                    <h5 className='page-heading mb-0 text-white pt-3'>Payments</h5>
                </div>
                <div className='contentContainer'>
                    <div className='d-flex mb-3'>
                        <button
                            className={`tab-button flex-1 ${activeTab === 'paid' ? 'active' : ''}`}
                            onClick={() => {
                                setActiveTab('paid');
                            }}
                        >
                            Paid
                        </button>
                        <button
                            className={`tab-button flex-1 ${activeTab === 'dues' ? 'active' : ''}`}
                            onClick={() => {
                                setActiveTab('dues');
                            }}
                        >
                            Dues
                        </button>
                    </div>
                    <div className='mt-3 mb-2'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label={'Month and Year'} views={['month', 'year']} className='cust-violet-input-field w-100'
                                value={dayjs(selectedDate)}
                                onChange={(ev) => {
                                    setSelectedDate(ev["$d"])
                                }} />
                        </LocalizationProvider>
                    </div>
                    {activeTab === 'paid' && paginatedData && paginatedData.length > 0 ? (
                        paginatedData.map((row, index) => (
                            <div className="card mobile-card mt-3" onClick={() => edit(row.payment_id)} key={index}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h6 className='mb-0 card-content'>{row.student_name}</h6>
                                    <ChevronRight className='iconColor' />
                                </div>
                                {/* <div className='text-end'>
                                        <p className='mb-0'>{row.date}</p>
                                    </div> */}
                            </div>
                        ))
                    ) : activeTab === 'dues' ? (
                        <>
                            <div className='text-end py-2'>
                                <button className='createButton'>Send Reminder</button>
                            </div>
                            {/* <div className="card mobile-card mt-2">
                                <div className='d-flex justify-content-between'>
                                    <h6 className='mb-0 card-content'>V.Giridhar</h6>
                                    <ChevronRight className='iconColor' />
                                </div>
                            </div>
                            <div className="card mobile-card">
                                <div className='d-flex justify-content-between'>
                                    <h6 className='mb-0 card-content'>D.Rajesh</h6>
                                    <ChevronRight className='iconColor' />
                                </div>
                            </div>
                            <div className="card mobile-card">
                                <div className='d-flex justify-content-between'>
                                    <h6 className='mb-0 card-content'>B.Raghav</h6>
                                    <ChevronRight className='iconColor' />
                                </div>
                            </div> */}
                        </>
                    ) : (
                        <div className="card mobile-card mt-3">
                            <h6 className='mb-0 card-content'>No logs found</h6>
                        </div>
                    )}
                </div>
            </>
        </div>
    )
}

export default MobilePayments
