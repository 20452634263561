import React, { useEffect, useState } from 'react'
import "./editgoals.scss"
import { FormControl, InputLabel, MenuItem, Select, TextField, styled } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteGoal, getGoalInfo, getTherapies, updateGoal } from '../../../Api';
import Swal from 'sweetalert2';
import { ArrowBack, Delete } from '@mui/icons-material';
const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    width: '100%',
    color: '#000',
    '&.Mui-focused': {
        color: '#000',
    },
}));
const CustomSelect = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#8267ed',
    },
}));
function MobileEditGoals() {
    const navigate = useNavigate();
    const { goal_correl_id } = useParams();
    const [activities, setActivities] = useState([]);
    const [therapies, setTherapies] = useState([]);
    const [content, setContent] = React.useState({})

    const addActivity = () => {
        setActivities([...activities, { activity_name: "" }]);
    };

    const deleteActivity = (index) => {
        const updatedActivities = activities.filter((_, i) => i !== index);
        setActivities(updatedActivities);
    };

    const delGoal = (goal_id) => {
        Swal.fire({
            text: `Are you sure you want to delete the goal?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false,
        }).then(async (res) => {
            if (res.isConfirmed) {
                let apiRes = await deleteGoal(goal_id);
                console.log(apiRes);
                if (apiRes.status === "S") {
                    Swal.fire({
                        text: `Goal Deleted`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        fetchData();
                        navigate('/app/manage-goals-activities');
                    });
                }
            }
        })
    }

    const fetchData = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let apiData = await getGoalInfo(goal_correl_id);
        if (apiData.status === "S") {
            setContent(apiData.result_info);
            setActivities(apiData.result_info.activities || [])
        } else {
            setContent(null)
        }
        let therapiesData = await getTherapies(userData.account_code);
        if (therapiesData.status === "S") {
            setTherapies(therapiesData.result_info);
        } else {
            setTherapies([])
        }
    }
    const update = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        if (!content.goal_name) {
            Swal.fire({
                text: 'Please provide Goal name!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.goal_name.length > 255) {
            Swal.fire({
                text: 'Goal name should be lessthan 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!content.therapy_id) {
            Swal.fire({
                text: 'Please select Therapy',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (activities.length === 0) {
            Swal.fire({
                text: 'Please add an activity',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if ([...activities.filter((activity) => (activity.activity_name.length > 255))].length > 0) {
            Swal.fire({
                text: 'Please enter name for activity',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if ([...activities.filter((activity) => (activity.activity_name.length > 255))].length > 0) {
            Swal.fire({
                text: 'Activity name should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let apiData = await updateGoal({
                account_code: userData.account_code,
                goal_name: content.goal_name,
                goal_description: content.goal_description,
                therapy_id: content.therapy_id,
                activities: activities.map((activity) => activity.activity_name),
                goal_correl_id
            }, content.goal_id);
            console.log(apiData);
            if (apiData.status === "S") {
                Swal.fire({
                    text: 'Goal updated successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-goals-activities')
                })
            } else if (apiData.status === "E" && apiData.result_code === 404) {
                Swal.fire({
                    text: 'Goal not found',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-goals-activities')
                })
            } else if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            } else if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            } else {
                Swal.fire({
                    text: JSON.stringify(apiData),
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, [])
    const handleGoBack = () => {
        navigate('/app/manage-goals-activities'); // Equivalent to history.goBack()
    };
    return (
        <div className='mobile-editgoals'>
            <div className="bg-color">
                <div className='mb-3'>
                    <ArrowBack className='text-white' style={{ cursor: 'pointer' }} onClick={handleGoBack} />
                </div>
                <div className='text-center'>
                    <h5 className="page-heading text-white mb-2">Edit Goal</h5>
                </div>
            </div>
            <div className="contentContainer mt-3">
                <div className="row mb-lg-3">
                    <div className="col-12 mb-3">
                        <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Title" variant="outlined" autoComplete="off" required
                            value={content.goal_name || ''} onChange={(ev) => {
                                setContent({ ...content, goal_name: ev.target.value })
                            }} />
                    </div>
                </div>
                <div className="row mb-lg-3">
                    <div className="col-12 mb-3">
                        <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Description" multiline
                            rows={3} variant="outlined" autoComplete="off"
                            value={content.goal_description || ''}
                            onChange={(ev) => {
                                setContent({ ...content, goal_description: ev.target.value })
                            }} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 mb-3">
                        <FormControl fullWidth required>
                            <CustomInputLabel id="demo-simple-select-label">Therapy</CustomInputLabel>
                            <CustomSelect
                                disabled
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={content.therapy_id}
                                label="Therapy"
                                key={content.therapy_id}
                                onChange={(ev) => setContent({ ...content, therapy_id: ev.target.value })}
                            >
                                {therapies && therapies.map((therapy) =>
                                    <MenuItem key={"therapy-" + therapy.therapy_id} value={therapy.therapy_id}>{therapy.therapy_name}</MenuItem>
                                )}
                            </CustomSelect>
                        </FormControl>
                    </div>
                    <div className='col-12'>
                        {activities && activities.length > 0 && activities.map((activity, index) => <div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <h6 className='mt-2'>Activity {index + 1}</h6>
                                <Delete onClick={() => {
                                    deleteActivity(index);
                                }} className='text-danger' />
                            </div>
                            <div>
                                <TextField className='w-100 mt-1 mb-2 cust-violet-input-field' id="outlined-basic" label="Name" variant="outlined" autoComplete="off" required
                                    value={activity.activity_name}
                                    onChange={(ev) => {
                                        let newActivities = [...activities].map((mapAc, mapInd) => {
                                            if (mapInd === index) {
                                                mapAc.activity_name = ev.target.value;
                                            }
                                            return mapAc
                                        });
                                        setActivities(newActivities);
                                    }} />
                            </div>
                        </div>)}
                    </div>
                    <div className="col-12 mt-3 text-end mb-5">
                        <button className='createButton edit-activity' onClick={addActivity}>Add Activity</button>
                    </div>
                </div>
                <div className="row buttonsRow d-flex px-4 py-3 bg-white fixed-bottom">
                <button className='cancelButton me-2 flex-1' onClick={() => { handleGoBack() }}>Cancel</button>
                    {/* <button className='cancelButton me-2 flex-1' onClick={() => { delGoal(content.goal_id) }}>Delete</button> */}
                    <button className='createButton flex-1' onClick={() => { update(content.goal_id) }}>Update</button>
                </div>
            </div>
        </div>
    )
}

export default MobileEditGoals
