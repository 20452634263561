import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import "./viewpayments.scss"
import { TextField } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

function ViewPayments() {
    const [searchQuery, setSearchQuery] = useState('');
    const [empData, setEmpData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [totalPages, setTotalPages] = useState(null);

    const filteredData = [...empData].filter(row =>
        Object.values(row).some(value =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const handleSearchChange = event => {
      setSearchQuery(event.target.value);
      setCurrentPage(1); // Reset current page when the search query changes
    };

    const paginatedData = [...filteredData].slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
  return (
    <div className='viewpaymentsContainer'>
        <h5 className='page-heading'>View Payments</h5>
        <div className='payments-page searchbar-div mb-4'>
            <div className='payments-page searchbar'>
                <TextField id="standard-basic" label="Search" variant="standard" value={searchQuery} onChange={handleSearchChange} />
            </div>
            <div className="payments-page buttonCreate text-end">
                <button className='create'>Send Reminder</button>
            </div>
        </div>
        <div className="card manage-card">
            <div className="tableContainer common-table">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Student Name</th>
                            <th>Payment Dues</th>
                            <th className='col-width'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData && (paginatedData.length > 0) && paginatedData.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.emp_name}</td>
                                    <td>{row.phone}</td>
                                    <td>{row.designation}</td>
                                    <td className='col-width'>
                                    <button className="btn btn-outline-primary px-4 me-2">Edit</button>
                                    <button className="btn btn-outline-danger px-3">Delete</button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <div className="d-flex justify-content-end align-items-center">
                    <button className="btn btn-light me-2" onClick={previousPage} disabled={currentPage === 1}>
                        <KeyboardArrowLeft />
                    </button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button className="btn btn-light ms-2" onClick={nextPage} disabled={currentPage === totalPages}>
                        <KeyboardArrowRight />
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ViewPayments
