import React, { useCallback, useEffect, useState } from 'react';
import "./dashboard.scss"
import { Link, useOutletContext } from 'react-router-dom';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { getAdminDashboard, getStudentDashboard, getTherapistDashboard } from '../../Api';
import { FormatListBulleted } from '@mui/icons-material';
import { MdLocalActivity } from 'react-icons/md';
import { TfiTarget } from "react-icons/tfi"

function Dashboard() {
    const { role } = useOutletContext();
    const [selectedMonth, setSelectedMonth] = useState(dayjs());
    const [isRole, setIsRole] = useState(null);
    const [content, setContent] = useState({
        total_students: "",
        total_therapists: "",
        today_summary: {},
        monthly_summary: {}

    });

    const [userData, setUserData] = useState({
        businessName: '',
        therapistName: '',
        parentName: ''
    });

    useEffect(() => {
        const storedUserData = JSON.parse(localStorage.getItem('userData') || '{}');
        setUserData({
            businessName: storedUserData.business_name || '',
            therapistName: storedUserData.therapist_name || '',
            parentName: storedUserData.parent_name || ''
        });
    }, []);

    const fetchRecords = useCallback(async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let userRole = JSON.parse(localStorage.getItem('role'));
        setIsRole(userRole);
        console.log(userData.account_code);
        let apiRes;

        let currentDate = new Date();
        const month = selectedMonth.month();
        let formattedDate = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate();

        if(userRole == 1){
            return
        }

        if (+userRole === 2) {
            apiRes = await getAdminDashboard(userData.account_code, formattedDate, month);
        }
        if (+userRole === 3) {
            apiRes = await getTherapistDashboard(userData.account_code, userData.therapist_id, formattedDate, month);
        }
        if (+userRole === 4) {
            apiRes = await getStudentDashboard(userData.account_code, userData.student_id, formattedDate, month);
        }

        if (apiRes && apiRes.status === 'S') {
            apiRes.today_summary.total_activities = apiRes.today_summary.total_activities === null ? 0 : apiRes.today_summary.total_activities;
            setContent(apiRes);
        } else if (apiRes && apiRes.result_code === 404) {
            setContent({});
        } else {
            setContent({});
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Something went wrong!',
            // });
        }

    }, []);

    useEffect(() => {
        fetchRecords()
    }, [fetchRecords])

    const renderSummary = () => (
        <div className='text-center mt-4'>
            <h5 className='section-heading mb-0 text-start mb-2'>Today's Summary</h5>
            {content &&
                <div className="row">
                    <div className="col-6 col-lg-6">
                        <Link to='/app/sessions-feedback' className='textDecoration'>
                            <div className="card color-card2 text-start" >
                                <div className='mb-2'>
                                    <FormatListBulleted className='text-center' />
                                </div>
                                <p className="summary-names mb-2">Sessions</p>
                                <h5 className='mb-0'>{content.today_summary.total_sessions}</h5>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 col-lg-6">
                        <Link to='/app/sessions-feedback' className='textDecoration'>
                            <div className="card color-card1 text-start">
                                <div className='mb-2'>
                                    <MdLocalActivity className='text-center' />
                                </div>
                                <p className="summary-names mb-2">Activities</p>
                                <h5 className='mb-0'>{content.today_summary.total_activities}</h5>
                            </div>
                        </Link>
                    </div>
                </div>
            }
        </div>
    );

    const renderMonthlySummary = () => (
        <div className='text-center mt-lg-4'>
            <h5 className='section-heading mb-0 text-start mt-2'>Monthly Summary</h5>
            <div className="row">
                <div className="col-12 col-lg-4">
                    <div className="card color-card2 text-start">
                        <div className='mb-2'>
                            <FormatListBulleted className='text-center' />
                        </div>
                        <p className="summary-names mb-2">Sessions</p>
                        <h5>{content.monthly_summary.total_sessions}</h5>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="card color-card1 text-start">
                        <div className='mb-2'>
                            <MdLocalActivity className='text-center' />
                        </div>
                        <p className="summary-names mb-2">Activities</p>
                        <h5>{content.monthly_summary.total_activities}</h5>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="card color-card2 text-start">
                        <div className='mb-2'>
                            <TfiTarget className='text-center' />
                        </div>
                        <p className="summary-names mb-2">Goals</p>
                        <h5>{content.monthly_summary.total_goals}</h5>
                    </div>
                </div>
            </div>
        </div>
    );



    const renderTherapistSummary = () => (
        <div className='text-center'>
            <h5 className='section-heading mb-0 text-start mt-2'>Today’s Summary</h5>
            <div className="row">
                <div className="col-6 col-lg-6">
                    <Link to='/app/sessions-feedback' className='textDecoration'>
                        <div className="card color-card2 text-start">
                            <div className='mb-2'>
                                <FormatListBulleted className='text-center' />
                            </div>
                            <p className="summary-names mb-2">Sessions</p>
                            <h5 className='mb-0'>{content.today_summary.total_sessions}</h5>
                        </div>
                    </Link>
                </div>
                <div className="col-6 col-lg-6">
                    <Link to='/app/sessions-feedback' className='textDecoration'>
                        <div className="card color-card1 text-start">
                            <div className='mb-2'>
                                <MdLocalActivity className='text-center' />
                            </div>
                            <p className="summary-names mb-2">Activities</p>
                            <h5 className='mb-0'>{content.today_summary.total_activities}</h5>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );

    const renderTherapistMonthlySummary = () => (
        <div className='text-center mt-lg-4 '>
            <h5 className='section-heading mb-0 text-start mt-2'>Monthly Summary</h5>
            <div className="row">
                <div className="col-12 col-lg-4">
                    <div className="card color-card2 text-start">
                        <div className='mb-2'>
                            <FormatListBulleted className='text-center' />
                        </div>
                        <p className="summary-names mb-2">Sessions</p>
                        <h5>{content.monthly_summary.total_sessions}</h5>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="card color-card1 text-start">
                        <div className='mb-2'>
                            <MdLocalActivity className='text-center' />
                        </div>
                        <p className="summary-names mb-2">Activities</p>
                        <h5>{content.monthly_summary.total_activities}</h5>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="card color-card2 text-start">
                        <div className='mb-2'>
                            <TfiTarget className='text-center' />
                        </div>
                        <p className="summary-names mb-2">Goals</p>
                        <h5>{content.monthly_summary.total_goals}</h5>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderParentSummary = () => (
        <div className='text-center'>
            <h5 className='section-heading mb-0 text-start mt-2'>Today’s Summary</h5>
            <div className="row">
                <div className="col-6 col-lg-6">
                    <Link to='/app/sessions-feedback' className='textDecoration'>
                        <div className="card color-card2 text-start">
                            <div className='mb-2'>
                                <FormatListBulleted className='text-center' />
                            </div>
                            <p className="summary-names mb-2">Sessions</p>
                            <h5>{content.today_summary.total_sessions}</h5>
                        </div>
                    </Link>
                </div>
                <div className="col-6 col-lg-6">
                    <Link to='/app/sessions-feedback' className='textDecoration'>
                        <div className="card color-card1 text-start">
                            <div className='mb-2'>
                                <MdLocalActivity className='text-center' />
                            </div>
                            <p className="summary-names mb-2">Activities</p>
                            <h5>{content.today_summary.total_activities}</h5>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );

    const renderParentMonthlySummary = () => (
        <div className='text-center mt-lg-4 '>
            <h5 className='section-heading mb-0 text-start mt-2'>Monthly Summary</h5>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="card color-card2 text-start">
                        <div className='mb-2'>
                            <FormatListBulleted className='text-center' />
                        </div>
                        <p className="summary-names mb-2">Sessions</p>
                        <h5>{content.monthly_summary.total_sessions}</h5>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="card color-card1 text-start">
                        <div className='mb-2'>
                            <MdLocalActivity className='text-center' />
                        </div>
                        <p className="summary-names mb-2">Activities</p>
                        <h5>{content.monthly_summary.total_activities}</h5>
                    </div>
                </div>


            </div>
        </div>
    );

    return (
        <>
            {+role === 2 && (
                <div className='createContainer'>
                    <div className='bg-color'>
                        <h5 className='page-heading mt-0'>Hello {userData.businessName},</h5>
                    </div>
                    <div className='contentContainer'>
                        {renderSummary()}
                        {renderMonthlySummary()}
                    </div>

                </div>
            )}
            {+role === 3 && (
                <div className='createContainer'>
                    <div className='bg-color '>
                        <h5 className='page-heading mt-0'>Hello {userData.therapistName},</h5>
                    </div>
                    <div className='contentContainer'>
                        {renderTherapistSummary()}
                        {renderTherapistMonthlySummary()}
                    </div>

                </div>
            )}
            {+role === 4 && (
                <div className='createContainer'>
                    <div className='bg-color '>
                        <h5 className='page-heading mt-0'>Hello {userData.parentName},</h5>
                    </div>
                    <div className='contentContainer'>
                        {renderParentSummary()}
                        {renderParentMonthlySummary()}
                    </div>
                </div>
            )}
        </>
    );
}

export default Dashboard;
