import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import "./createtherapist.scss"
import { styled } from '@mui/material/styles';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material'
import Swal from 'sweetalert2';
import { createTherapist, getTherapies } from '../../../Api';
const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    width: '100%',
    color: '#000',
    '&.Mui-focused': {
        color: '#000',
    },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#8267ed',
    },
}));
const ColoredCheckbox = styled(Checkbox)(({ theme }) => ({
    color: theme.palette.main,
    '&.Mui-checked': {
        color: '#8267ed',
        borderColor: '#8267ed',
    },
}));
function CreateTherapists() {
    const navigate = useNavigate();
    const [selectedTherapies, setSelectedTherapies] = useState([]);
    const [therapies, setTherapies] = useState([]);

    const handleTherapyChange = (event) => {
        const { value } = event.target;
        setSelectedTherapies(value);
        console.log(value);
        const selectedTherapy = value.map(therapy => therapy.therapy_id);
        setContent({ ...content, specialization: selectedTherapy });
    };
    const [userInfo, setUserInfo] = useState(null)
    const [content, setContent] = React.useState({
        therapist_name: "",
        email: "",
        phone: "",
        specialization: [],

    })
    const submit = async () => {
        Swal.fire({
            html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
            showConfirmButton: false,
            heightAuto: false,
            customClass: { popup: 'loader-swal' }
        });
        let emalval = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
        if (!content.therapist_name) {
            Swal.fire({
                text: 'Please provide Therapist name!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.therapist_name.length > 255) {
            Swal.fire({
                text: 'Therapist name should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (selectedTherapies.length === 0) {
            Swal.fire({
                text: 'Please select specialization',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!content.phone) {
            Swal.fire({
                text: 'Please enter Mobile number',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.phone.length > 255) {
            Swal.fire({
                text: 'Mobile number should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.email.length > 255) {
            Swal.fire({
                text: 'Email should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.email && !emalval.test(content.email)) {
            Swal.fire({
                text: 'Please enter valid Email',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let data = { ...content, specialization: content.specialization.join(",") };
            if (data.email) {
                data.email = data.email.toLowerCase();
            }
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            data.account_code = userData.account_code;
            console.log(data);
            let apiData = await createTherapist({ ...data });
            console.log(apiData);
            if (apiData.status === "S") {
                Swal.fire({
                    text: 'Therapist created successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-therapists')
                })
            }
            if (apiData.status === "E" && apiData.status_code === '300') {
                Swal.fire({
                    text: 'Email is already taken',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
            }else if (apiData.status === "E") {
                Swal.fire({
                    text: apiData.message,
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        }
    }

    useEffect(() => {
        (async () => {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let acc_code = userData.account_code;
            let apiData = await getTherapies(acc_code);
            apiData.status === "S" ? setTherapies(apiData.result_info) : setTherapies([]);
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        })();
    }, [navigate])

    useEffect(() => {
        (async () => {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            setUserInfo(userData);
        })()
    }, [])

    return (

        <div className='createContainer'>
            <h5 className='page-heading'>Create Therapists</h5>

            <div className="row mb-lg-3">
                <div className="col-12 col-lg-6 mb-3">
                    <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Name" variant="outlined" autoComplete="off" required value={content.therapist_name} onChange={(ev) => {
                        setContent({ ...content, therapist_name: ev.target.value })
                    }} />
                </div>
                <div className="col-12 col-lg-6 mb-3">
                    <FormControl fullWidth required>
                        <CustomInputLabel id="demo-multiselect-label">Specialization</CustomInputLabel>
                        <CustomSelect
                            labelId="demo-multiselect-label"
                            id="demo-multiselect"
                            multiple
                            value={selectedTherapies}
                            onChange={handleTherapyChange}
                            label="Specialization"
                            renderValue={(selected) => {
                                let name = ''
                                selected.forEach((val) => {
                                    name = name ? name + ', ' + val.therapy_name : name + val.therapy_name
                                })
                                return name;
                            }}
                        >
                            {therapies.map((therapy) => (
                                <MenuItem key={therapy.therapy_id} value={therapy}>
                                    <ColoredCheckbox checked={selectedTherapies.some(selected => selected.therapy_id === therapy.therapy_id)} />
                                    <ListItemText primary={therapy.therapy_name} />
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                </div>
            </div>
            <div className="row mb-lg-3">
                <div className="col-12 col-lg-6 mb-3">
                    <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Mobile" variant="outlined" autoComplete="off" required
                        value={content.phone}
                        onChange={(ev) => {
                            setContent({ ...content, phone: ev.target.value })
                        }} />
                </div>
                <div className="col-12 col-lg-6 mb-3">
                    <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Email" variant="outlined" autoComplete="off" required
                        value={content.email}
                        onChange={(ev) => {
                            setContent({ ...content, email: ev.target.value })
                        }} />
                </div>
            </div>
            <div className="row buttonsRow">
                <button className='createButton me-3' onClick={() => { submit() }}>Create</button>
                <button className='cancelButton' onClick={() => { navigate('/app/manage-therapists') }}>Cancel</button>
            </div>

        </div>

    )
}

export default CreateTherapists
