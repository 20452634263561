import React, { useEffect, useState } from 'react'
import "./navbar.scss"
import { MdMenu, MdOutlinePerson } from "react-icons/md";
function Navbar({ trigger }) {
  
    const [userName, setUserName] = useState('');
    const [role, setRole] = useState('');

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData') || '{}');
        const storedRole = JSON.parse(localStorage.getItem('role') || '""');
        
        if (userData && storedRole) {
            setRole(storedRole);
            switch (storedRole) {
                case '4':
                    setUserName(userData.student_name || '');
                    break;
                case '3':
                    setUserName(userData.therapist_name || '');
                    break;
                case '2':
                    setUserName(userData.business_name || '');
                    break;
                default:
                    setUserName('');
            }
        }
    }, []);
  
  return (
    <div className='navbarContainer'>
      <nav className="navbar navbar-expand-lg">
      <div className="container-fluid navbarContent">
        <p className="navbar-brand mb-0"><MdMenu fontSize={"25px"} className='menu-icon' onClick={trigger}/></p>
          <div className="collapse navbar-collapse justify-content-start" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <img className='logo' src={require('../../assets/logo-3.png')} alt="IMPAXIFY - Software-for-child-development-centers" />
              </li>
            </ul>
            
          </div>
          <div className="collapse navbar-collapse justify-content-end d-flex" id="navbarSupportedContent2">
            <div className='me-3'>
              <h6 className='navbar-lastText p-0 m-0 d-none d-lg-block'>{userName}</h6>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
