import React, { useEffect, useState } from 'react'
import "./creategoals.scss"
import { FormControl, InputLabel, MenuItem, Select, TextField, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { createGoal, getTherapies } from '../../../Api';
import { ArrowBack, Delete } from '@mui/icons-material';
const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    width: '100%',
    color: '#000',
    '&.Mui-focused': {
        color: '#000',
    },
}));
const CustomSelect = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#8267ed',
    },
}));
function MobileCreateGoals() {
    const navigate = useNavigate();
    const [activities, setActivities] = useState([]);
    const [therapies, setTherapies] = useState([]);
    const [content, setContent] = React.useState({
        goal_name: "",
        goal_description: "",
        therapy_id: "",
    })

    const addActivity = () => {
        setActivities([...activities, { name: "" }]);
    };

    const deleteActivity = (index) => {
        const updatedActivities = activities.filter((_, i) => i !== index);
        setActivities(updatedActivities);
    };

    const submit = async () => {
        if (!content.goal_name) {
            Swal.fire({
                text: 'Please provide Goal name!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (content.goal_name.length > 255) {
            Swal.fire({
                text: 'Goal name should be lessthan 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!content.therapy_id) {
            Swal.fire({
                text: 'Please select Therapy',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (activities.length === 0) {
            Swal.fire({
                text: 'Please add an activity',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (activities.filter((activity) => activity.name === '').length > 0) {
            Swal.fire({
                text: 'Please enter name for activity',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (activities.filter((activity) => (activity.name.length > 255)).length > 0) {
            Swal.fire({
                text: 'Activity name should be less than 255 characters',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let formData = { ...content, account_code: userData.account_code, activities: [...activities.map((activity) => activity.name)] };
            console.log(formData);
            let apiData = await createGoal(formData);
            console.log(apiData);
            if (apiData.status === "S") {
                Swal.fire({
                    text: 'Goal created successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-goals-activities')
                })
            }
            if (apiData.status === "E") {
                Swal.fire({
                    text: apiData.message,
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        }
    }

    useEffect(() => {
        (async () => {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let acc_code = userData.account_code;
            let apiData = await getTherapies(acc_code);
            apiData.status === "S" ? setTherapies(apiData.result_info) : setTherapies([]);
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Missing token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if (apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token") {
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        })();
    }, [navigate])
    const handleGoBack = () => {
        navigate('/app/manage-goals-activities'); // Equivalent to history.goBack()
    };
    return (
        <div className='mobile-creategoals'>
            <div className="bg-color">
                <div className='mb-3'>
                    <ArrowBack className='text-white' style={{ cursor: 'pointer' }} onClick={handleGoBack} />
                </div>
                <div className='text-center'>
                    <h5 className="page-heading text-white mb-2">Create Goal</h5>
                </div>
            </div>
            <div className="contentContainer mt-3">
                <div className="row mb-lg-3">
                    <div className="col-12 mb-3">
                        <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Title" variant="outlined" autoComplete="off" required
                            onChange={(ev) => {
                                setContent({ ...content, goal_name: ev.target.value })
                            }} />
                    </div>
                </div>
                <div className="row mb-lg-3">
                    <div className="col-12 mb-3">
                        <TextField className='w-100 cust-violet-input-field' id="outlined-basic" label="Description" multiline
                            rows={3} variant="outlined" autoComplete="off"
                            onChange={(ev) => {
                                setContent({ ...content, goal_description: ev.target.value })
                            }}
                        />
                    </div>

                </div>
                <div className="row mb-3">
                    <div className="col-12 mb-3">
                        <FormControl fullWidth required>
                            <CustomInputLabel id="demo-simple-select-label">Therapy</CustomInputLabel>
                            <CustomSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={content.therapy_id}
                                label="Therapy"
                                onChange={(ev) => setContent({ ...content, therapy_id: ev.target.value })}
                            >
                                {therapies && therapies.map((therapy) =>
                                    <MenuItem key={"therapy-" + therapy.therapy_id} value={therapy.therapy_id}>{therapy.therapy_name}</MenuItem>
                                )}
                            </CustomSelect>
                        </FormControl>
                    </div>
                    <div className='col-12'>
                        {activities && activities.length > 0 && activities.map((activity, index) => <div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <h6 className='mt-2'>Activity {index + 1}</h6>
                                <Delete className='text-danger' onClick={() => {
                                    deleteActivity(index);
                                }} />
                            </div>
                            <div>
                                <TextField className='w-100 mb-2 cust-violet-input-field' id="outlined-basic" label="Name" variant="outlined" autoComplete="off" required
                                    value={activity.name}
                                    onChange={(ev) => {
                                        let newActivities = [...activities].map((mapAc, mapInd) => {
                                            if (mapInd === index) {
                                                mapAc.name = ev.target.value;
                                            }
                                            return mapAc
                                        });
                                        setActivities(newActivities);
                                    }} />
                            </div>
                        </div>)}
                    </div>
                    {content.therapy_id && (
                        <div className="col-12 mt-3 text-end">
                            <button className='createButton add-activity' onClick={addActivity}>Add Activity</button>
                        </div>
                    )}

                </div>

                <div className="row buttonsRow d-flex px-2">
                    <button className='cancelButton me-2 flex-1' onClick={() => { navigate('/app/manage-goals-activities') }}>Cancel</button>
                    <button className='createButton flex-1' onClick={() => { submit() }}>Create</button>
                </div>
            </div>
        </div>
    )
}

export default MobileCreateGoals
